.main {
  width: 100%;
  display: flex;
}

.main__sidebar {
  width: 25%;
  height: 100%;
  padding: 40px 10px;
}

.main__content {
  padding: 1px;
}


.menu {
  margin-top: 3px;
  background-color: rgba(80, 170, 81, 0.2);
  max-width: 300px;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.menu svg {
  font-size: 23px;
  color: rgb(80,170,81);
}
.menu span {
  padding-left: 30px;
  position: relative;
  top: -5px;
  color: rgb(80,170,81);
  
}

.active {
  width: 100%;
  font-weight:bolder;
}

@media only screen and (max-width: 600px) {
  .main {
    flex-direction: column;
  }
  
  .main__sidebar {
    width: 100%;
    padding: 20px;
  }
  
  .main__content {
    width: 100%;
    padding: 20px;
  }
  
  .mapa {
    height: 200px;
    width: 100%;
  }
  
  .map-marker {
    margin-top: -15px;
    font-size: 20px;
  }
  
  .menu {
    max-width: 100%;
    margin: 10px 0;
  }
}