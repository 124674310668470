
h2 {
  font-size: 23px;
  color: rgb(80,170,81);
}

.Msg_dados {
  width: 50%;
  height: 48px;
  background: rgb(80,170,81);
  border-radius: 12px;
  color: #fff;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  border-bottom: 1px solid #ccc;
  align-items:center;
  display: flex;
  justify-content:space-between;
  gap: 15px;
}
.Msg_dados:hover {
  cursor: pointer;
  opacity: 0.8;
}