.containerHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px 8px;
  border-bottom: 1px solid #ccc;

}

.headerImg img {
  width: 70px;
}

.headerUser {
  padding-top: 10px;
}

.headerUser span {
  font-weight: bold;
  color: #50AA51;
}

.headerSair {
  width: 60px;
  

}
.headerSair button {
  padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 15px;
    color: #333;
    font-weight: bold;
    cursor: pointer;
}