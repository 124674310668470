.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .texto span{
    padding: 50px;
    margin: 20px;
  }
