.ocorrencias_detalhe {
  display: flex;
  flex-direction: column;
  color: rgb(80,170,81);
  margin-top: 30px;
  padding: 10px;
}

h2 {
  font-size: 23px;
  color: rgb(80,170,81);
}




.ocorrenciasBotao {
  background-color: rgba(80,170,81, 0.2);
  display: flex;
  justify-content: center;
  padding: 10px 8px;
  cursor: pointer;
  border-radius: 8px;
}

.ocorrenciasBotao span{
  font-size: 20px;
  padding: 0 15px 0 0;
}

.icon {
  font-size: 20px;
}

.cabecalho {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.dados {
  border-bottom: 1px solid #ccc;
  align-items: flex-start;
}
.sobre {
  display: inline-block;
  width: 35%;
}

.tipo {
  display: inline-block;
  width: 20%;
}

.data {
  display: inline-block;
  width: 20%;
}

.estado {
  display: inline-block;
  width: 25%;
}

.dados {
  padding: 10px;
}

.atualizar{
  display: inline-block;
  position: absolute;
  color: rgb(80,170,81);
  cursor: pointer;
}

.atualizar form{
  float: left;
  position: absolute;
  right: 0.7rem;
  top: -0.5rem;
  text-decoration: none;
  visibility: hidden;

}

.atualizar:hover form {
  visibility: visible;
  left: auto;
  display: block;
  box-shadow: 0px 0px 0.1rem;
  position: absolute;
  z-index: 1000;
  opacity: 0.9;
  background-color: #fff;
}

input {
  display: flex;
  background-color: rgba(80,170,81,0.2);
  color: rgb(80,170,81);
  margin-top: 2px;
  width: 150px;
  max-width: 300px;
  padding: 10px;
  border-radius: 8px;
  border-color: rgba(80,170,81,0.2);
  cursor: pointer;
}

