
.container .logoImgReg {
  height: calc(100vh - 70%);
  width: calc(100vw - 50%);
}
  .container .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
  }
  
  .container .selecao {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .container label {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
  }

  .container .selecao select {
    appearance: none;
      padding: 10px;
      font-size: 16px;
      border: 1px solid #5C73DB;
      border-radius: 4px;
      background-color: #7e91e6;
      box-shadow: 0 2px 4px rgba(132, 106, 226, 0.1);
      outline: none;
      transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }

  .container .selecao select:focus {
    border-color: #007bff;
    box-shadow: 0 2px 4px rgba(0, 123, 255, 0.25);
  }

  .container form {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  
  .container form  .inputContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .container form  .inputContainer label {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
  }
  
  .container form  .inputContainer input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    width: 520px;
    height: 49px;
    border: 1px solid #5C73DB;
    border-radius: 12px;
    margin-bottom: 12px;
  
  }
  
  .container form a {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-top: 16px;
    padding-bottom: 16px;
    color: #5C73DB;
  }
  
  .container form .button {
    width: 520px;
    height: 48px;
    background: rgb(80,170,81);
    border-radius: 12px;
    color: #fff;
    border: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .container form .button:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  
  .container form .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px
  }
  
  .container form .footer p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  
  .container form .footer a {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #5C73DB;
  }
  
  @media screen and (max-width: 480px) {
    .container .logoImgReg {
      height: calc(100vh - 300px);
      width: calc(100vw - 20px);
    }
    
    .container form  .inputContainer input {
      width: 90%;
    }
    
    .container form .button {
      width: 90%;
    }
  }
  