* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

html, body {
  font-family: 'Inter', sans-serif;
}


