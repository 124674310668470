h2 {
  font-size: 23px;
  color: rgb(80,170,81);
}

.ocorrencias_detalhe {
  display: flex;
  align-content: flex-start;
  color: rgb(80,170,81);
  margin-top: 30px;
  padding: 10px;
  width: 100%;
}


 
.mensagem input {
  display: flex;
  background-color: rgba(80,170,81,0.2);
  color: rgb(14, 13, 13);
  margin-top: 2px;
  height: 49px;
  width: 1500px;
  max-width: 80%;
  padding: 20px;
  border: 1px solid #5C73DB;
  border-radius: 12px;
  margin-bottom: 12px;
  cursor: auto;
}


.mapa form .ocorrenciasBotao {
  width: 50%;
  height: 48px;
  background: rgb(80,170,81);
  border-radius: 12px;
  color: #fff;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.mapa form .ocorrenciasBotao:hover {
  cursor: pointer;
  opacity: 0.8;
}


