.relatorios {
  display: flex;
  flex-direction: column;
  color: rgb(80,170,81);
  margin-top: 30px;
  padding: 10px;
  width: 100%;
}

.barra {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.pizza {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}

h2 {
  font-size: 23px;
  color: rgb(80,170,81);
}



